<template>
  <div class="p-3">
    <b-row class="bg-tabs-custom">
      <b-col><span>Transaction Detail</span></b-col>
      <b-col class="text-right"
        ><span class="transaction-date"
          >Date/Time Transaction:
          {{ $moment(detail.created_time).format("DD/MM/YYYY HH:mm:ss") }}
        </span>
      </b-col>
    </b-row>
    <b-row class="bg-white content-section">
      <b-col :cols="show ? 4 : 6">
        <b-row>
          <b-col class="label-transactions" sm="5">
            <div>Ref No.</div>
            <div>:</div>
          </b-col>
          <b-col sm="6">
            <template v-if="show">
              {{ detail.invoice_no }}
              {{
                detail.transaction_ref_id
                  ? "(" + detail.transaction_ref_id + ")"
                  : ""
              }}
            </template>
            <template v-else>
              <InputText
                textFloat=""
                placeholder="Ref No."
                class="mb-0"
                v-model="detail.invoice_no"
              />
            </template>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="label-transactions" sm="5"
            ><div>Buyer Name</div>
            <div>:</div></b-col
          >
          <b-col sm="6">
            {{ detail.customer_name }}
            <button
              v-if="show"
              @click.prevent="
                $router.push(`/report/customer/search/${detail.telephone}`)
              "
              class="icon-to-point"
            >
              <font-awesome-icon icon="chevron-right" size="xs" />
            </button>
            <b-button
              v-else
              variant="custom-link"
              class="text-warning"
              @click.prevent="changeCustomer"
            >
              <font-awesome-icon icon="pencil-alt" size="xs" />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          ><b-col class="label-transactions" sm="5"
            ><div>Branch</div>
            <div>:</div></b-col
          ><b-col sm="6">{{ detail.plant_name }}</b-col></b-row
        >
        <b-row>
          <b-col class="label-transactions" sm="5"
            ><div>Net Price (Baht)</div>
            <div>:</div></b-col
          >
          <b-col sm="6">{{ detail.total | numeral("0,0.00") }}</b-col>
        </b-row>
        <b-row>
          <b-col class="label-transactions" sm="5"
            ><div>Status</div>
            <div>:</div>
          </b-col>
          <b-col sm="6">
            <span v-if="detail.status == 1" class="text-success">
              Complated</span
            >
            <span v-else-if="detail.status === 0" class="text-warning"
              >Pending</span
            >
          </b-col>
        </b-row>
      </b-col>

      <template v-if="show">
        <b-col cols="4">
          <b-row>
            <b-col class="label-transactions" sm="5"
              ><div>Sales Channel</div>
              <div>:</div></b-col
            ><b-col sm="6">
              {{ detail.channel_name || "CRM" }}
              {{
                detail.channel_name == "CRM" ||
                (!detail.channel_name && detail.source_name == "MIM")
                  ? `(${detail.source_name})`
                  : ""
              }}</b-col
            >
          </b-row>
          <b-row>
            <b-col class="label-transactions" sm="5">
              <div>Payment Channel</div>
              <div>:</div>
            </b-col>
            <b-col sm="6">
              {{ detail.payment_channel_name || "-" }}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="label-transactions" sm="5"
              ><div>Tracking No</div>
              <div>:</div></b-col
            ><b-col sm="6"> {{ detail.external_tracking_no || "-" }}</b-col>
          </b-row>
          <b-row>
            <b-col class="label-transactions" sm="5"
              ><div>External Order ID</div>
              <div>:</div></b-col
            ><b-col sm="6"> {{ detail.external_order_id || "-" }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-row>
            <b-col class="label-transactions" sm="5"
              ><div>Reward Center Point</div>
              <div>:</div></b-col
            >
            <b-col sm="6">{{ detail.center_point | numeral("0,0") }} </b-col>
          </b-row>
          <b-row>
            <b-col class="label-transactions" sm="5"
              ><div>Reward Branch Point</div>
              <div>:</div></b-col
            >
            <b-col sm="6">{{ detail.branch_point | numeral("0,0") }} </b-col>
          </b-row>
          <b-row>
            <b-col class="label-transactions" sm="5"
              ><div>Current Points</div>
              <div>:</div></b-col
            >
            <b-col sm="6" style="line-height: 1.2">
              <div class="d-flex justify-content-between">
                <div class="mr-3">Center Point (0)</div>
              </div>
              <div class="mt-1 d-flex justify-content-between">
                <div class="mr-3">Branch Point</div>
                <button @click.prevent="modalBranch" class="icon-to-point">
                  <font-awesome-icon icon="chevron-right" size="xs" />
                </button>
              </div>
            </b-col>
          </b-row>
          <!-- <b-row>
          <b-col class="label-transactions" sm="5">
            Additional Notes
            <font-awesome-icon
              icon="pencil-alt"
              size="xs"
              class="pointer"
              @click.prevent="editNote"
          /></b-col>
          <b-col sm="6" style="word-break: break-all">
            {{ detail.note ? detail.note : "-" }}
          </b-col>
        </b-row> -->
        </b-col>
      </template>
    </b-row>
    <ModalCustomerList :ref="`customerModal`" @select="handleChangeCustomer" />
  </div>
</template>

<script>
import ModalCustomerList from "@/components/ticket/activity/ModalCustomerList";
export default {
  components: {
    ModalCustomerList,
  },
  props: {
    detail: {
      required: true,
      type: Object,
    },
    centerPoint: {
      required: false,
    },
    show: {
      required: false,
      default: true,
    },
  },
  methods: {
    editNote() {
      this.$emit("modalEditNote");
    },
    modalBranch() {
      this.$emit("modalBranch");
    },
    modalCenterPoint() {
      this.$emit("modalCenterPoint");
    },
    changeCustomer(index) {
      // if (this.isCustomer || this.isEdit) return;
      // this.selectedIndex = index;
      this.$refs.customerModal.show();
    },
    handleChangeCustomer(val) {
      console.log(val);
      this.detail.telephone = val.telephone;
      this.detail.customer_name = val.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--theme-secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}

.ft-black {
  color: #000;
}

.consent-allow {
  color: #06c306;
}

.status-cancel {
  color: red;
}

.status-void {
  color: var(--primary-color);
}

.label-transactions {
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-section {
  padding: 15px 0;
  line-height: 23px;
  color: gray;
}

.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 3px;
}

.transaction-date {
  color: var(--primary-color);
}

.status-return-1 {
  color: #cd3000;
}

.min-width-fix {
  min-width: 150px;
}
</style>
