<template>
  <div v-if="!isLoading">
    <b-row class="no-gutters">
      <b-col class="text-left">
        <h1 class="mr-sm-4 header-tablepage">
          TRANSACTION DETAIL REF: {{ transactionDetail.invoice_no }}

          <font-awesome-icon
            v-if="transactionDetail.receipt_image_url"
            style="cursor: zoom-in"
            icon="receipt"
            :id="`hover-image-receipt`"
          ></font-awesome-icon>

          <b-popover
            :target="`hover-image-receipt`"
            triggers="focus hover"
            placement="righttop"
            fallback-placement="clockwise"
            delay="200"
            custom-class="hover-image-popover"
          >
            <b-img
              class="hover-preview"
              :src="
                transactionDetail.receipt_image_url ||
                '/img/default-placeholder.png'
              "
              fluid
            ></b-img>
          </b-popover>
        </h1>
      </b-col>

      <template v-if="!original">
        <b-col class="text-right">
          <b-button
            @click.prevent="editTransaction"
            variant="outline-primary"
            v-if="transactionDetail.status == 0"
          >
            <span class="d-none d-sm-block">Edit </span>
            <font-awesome-icon icon="paper-plane" class="d-sm-none d-block" />
          </b-button>
          <b-button
            @click.prevent="viewOriginal"
            variant="outline-primary"
            v-else
            title="View Original"
          >
            <span class="d-none d-sm-block">View Original</span>
          </b-button>
        </b-col>
      </template>
    </b-row>
    <TransactionDetailSection :detail="transactionDetail" centerPoint="0" />
    <TransactionProductSection
      :fields="fields"
      :items="transactionDetail.product"
      :discount="'discount'"
    />
    <SideBar ref="sidebar" @submit="updateTransaction">
      <template v-slot:content>
        <TransactionDetailSection :detail="transactionDetail" :show="false" />
        <TransactionProductSection
          :fields="fields"
          :items="transactionDetail.product"
          :discount="'discount'"
        />
        <div class="">
          <template v-for="(items, index) of transactionDetail.header_discount">
            <div :key="items.id + '-' + index">
              <b-row class="align-items-center mb-3 justify-content-end">
                <b-col cols="5" class="text-right">
                  <b> {{ items.name }}</b> <br />
                  <span class="color-gray">
                    {{ items.code }}
                  </span>
                </b-col>
                <b-col cols="4" class="position-relative">
                  <InputText
                    disabled
                    class="mb-0"
                    type="text"
                    :value="items.value"
                    placeholder=""
                  />
                  <div class="baht">Baht</div>
                </b-col>

                <!-- <template v-if="items.code"> -->
                <div class="break-normal"></div>

                <b-col cols="4" class="d-flex column-gap-lg mt-2">
                  <b-button
                    variant="outline-primary"
                    style="flex: 1"
                    @click="changeCouponRef(index)"
                  >
                    Change Coupon
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    style="flex: 1"
                    @click="changeDiscount(index, items)"
                  >
                    Add Discount
                  </b-button>
                </b-col>
                <!-- </template> -->
                <!-- </div> -->
              </b-row>
            </div>
          </template>
        </div>
        <hr />

        <b-row class="align-items-center mb-3 justify-content-end">
          <b-col cols="5" class="text-right">
            <b>Net.</b>
          </b-col>
          <b-col cols="4" class="position-relative">
            <InputText
              textFloat=""
              type="text"
              disabled
              placeholder=""
              class="mb-0"
              :value="addCommas(transactionDetail.total, '0,0')"
            >
              <!-- <template v-slot:textFloat></template> -->
            </InputText>
            <div class="baht">Baht</div>
          </b-col>
        </b-row>
      </template>
    </SideBar>
    <b-modal
      centered
      title="Change Coupon"
      v-model="couponModal"
      @ok="saveChangeCoupon"
      @hidden="clearValue"
    >
      <InputText
        textFloat="Coupon Code"
        type="text"
        placeholder="Coupon Code"
        v-model="ref"
        class="mb-0"
      >
      </InputText>
    </b-modal>
    <HeaderDiscountModal
      ref="headerModal"
      @saveDiscount="saveDiscount"
      @clearValue="clearValue"
    />
    <footer-action backAction hideSubmit @routePath="backAction" />
  </div>
</template>

<script>
import TransactionDetailSection from "./components/DetailView";
import TransactionProductSection from "./components/Product";
import HeaderDiscountModal from "./components/HeaderDiscountModal";
import SideBar from "./components/Sidebar";
export default {
  components: {
    TransactionDetailSection,
    TransactionProductSection,
    SideBar,
    HeaderDiscountModal,
  },
  data() {
    return {
      transactionDetail: {},
      fields: [
        {
          label: "SKU / Description / Promotion",
          key: "article_no",
        },
        {
          label: "Quantity",
          key: "q",
        },
        {
          label: "Price (Baht)",
          key: "price",
        },
        {
          label: "Promotional Price (Baht)",
          key: "promotion_price",
        },
        {
          label: "Discount (Baht)",
          key: "net_discount",
        },
        {
          label: "Net Price (Baht)",
          key: "net_price",
        },
      ],
      items: [],
      isLoading: true,
      ref: "",
      couponModal: false,
      changeCouponIndex: null,
      original: false,
      cloneHeader: null,
    };
  },
  created() {
    if (this.$route.meta.original) this.getDataOriginal();
    else this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await this.axios(
        "/transaction/transactionpending/detail/" + this.$route.params.id
      );
      this.transactionDetail = res.data.detail;
      if (res.data.detail.header_discount.length > 0)
        this.cloneHeader = JSON.parse(
          JSON.stringify(res.data.detail.header_discount)
        );

      this.isLoading = false;
      //   this.items = :items="transactionDetail.product_list"
    },
    editTransaction() {
      this.$refs.sidebar.show();
    },
    saveChangeCoupon() {
      // this.updateType = "coupon";
      this.transactionDetail.header_discount[this.changeCouponIndex] =
        JSON.parse(JSON.stringify(this.cloneHeader[this.changeCouponIndex]));
      this.transactionDetail.header_discount[this.changeCouponIndex].code =
        this.ref;
    },
    changeCouponRef(index) {
      this.couponModal = true;
      this.changeCouponIndex = index;
    },
    changeDiscount(index, item) {
      this.changeCouponIndex = index;
      this.$refs.headerModal.show(item);
    },
    saveDiscount(item) {
      this.transactionDetail.header_discount[this.changeCouponIndex] =
        JSON.parse(JSON.stringify(this.cloneHeader[this.changeCouponIndex]));
      this.transactionDetail.header_discount[this.changeCouponIndex].name =
        item.name;
      this.transactionDetail.header_discount[this.changeCouponIndex].ref =
        item.promotion_ref;
      this.$refs.headerModal.hide();
    },
    clearValue() {
      this.changeCouponIndex = "";
      this.ref = "";
    },
    async updateTransaction() {
      this.$bus.$emit("showLoading");
      let payload = {
        ...this.transactionDetail,
        id: this.$route.params.id,
        user_guid: "",
      };

      const res = await this.axios.put(
        "/transaction/transactionpending",
        payload
      );
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1)
        this.successAlert().then(() =>
          this.$router.push("/report/transaction-pending")
        );
      else this.errorAlert(res.data.message);
    },
    viewOriginal() {
      this.$router.push(
        "/report/transaction-pending/original/" + this.$route.params.id
      );
    },
    async getDataOriginal() {
      this.isLoading = true;
      const res = await this.axios(
        "/transaction/transactionpending/detail/original/" +
          this.$route.params.id
      );
      this.transactionDetail = res.data.detail;
      this.original = true;
      this.isLoading = false;
    },
    backAction() {
      if (!this.original)
        return this.$router.push("/report/transaction-pending");
      else
        return this.$router.replace(
          "/report/transaction-pending/" + this.$route.params.id
        );
    },
  },
};
</script>

<style>
.baht {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  color: gray;
}
.w-30 {
  width: 32%;
}
</style>
